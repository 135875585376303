<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? $t('cip.plat.sys.knowledge.title.editHeadTitle') : ( this.type == 'detail' ? $t('cip.plat.sys.knowledge.title.viewHeadTitle') : $t('cip.plat.sys.knowledge.title.addHeadTitle'))"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/knowledgeCate";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
let bizUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {mapGetters} from "vuex";

import {
  saveOrUpdate,
  selectById
} from "@/api/system/knowledge";


export default {
  name: "extItemEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
      nodeData: {},
    }
  },
  computed: {
    ...mapGetters(['language']),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBackBtn`),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
            btnOptType: 'saveBack',
          }
        );
      }
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let this_ = this;
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 60) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength6Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.plat.sys.knowledge.field.knowledgeName') + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t('cip.plat.sys.knowledge.field.knowledgeCode'),
          labelWidth: 130,
          prop: "knowledgeCode",
          readonly: !['add'].includes(this.type),
          maxlength: 50,
          disabled: ('edit').includes(this.type),
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.sys.knowledge.field.knowledgeCode`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.knowledgeCode'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.plat.sys.knowledge.field.knowledgeName'),
          labelWidth: 130,
          prop: "knowledgeName",
          span: 8,
          isGlobal: true, //是否支持国际化
          read: !['edit', 'add'].includes(this.type),
          maxlength: 20,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.sys.knowledge.field.knowledgeName`),
          rules: [{required: true, validator: validatePass, trigger: ['blur', 'change']}],
        },
        {
          label: this.$t('cip.plat.sys.knowledge.field.knowledgeTypeName'),
          labelWidth: 130,
          type: 'input',
          prop: "knowledgeTypeName",
          readonly: true,
          placeholder: this.$t(`cip.plat.sys.knowledge.field.knowledgeTypeName`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.knowledgeTypeName'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.plat.sys.knowledge.field.filePath'),
          prop: 'picPath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.sys.knowledge.msg.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          row: true,
          accept: 'image/png, image/jpeg',
          tip: this.$t('cip.cmn.rule.photograph'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          display: true,
          readonly: !['edit', 'add'].includes(this.type),
        },
        {
          label: this.$t(`cip.plat.sys.knowledge.field.remark`),
          prop: "remark",
          labelWidth: 130,
          type: "textarea",
          overHidden: "15",
          placeholder: this.$t(`cip.plat.sys.knowledge.field.remark`),
          span: 16,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.remark'),
            trigger: "blur"
          }],
        },
      ]
    },
  },
  created() {
    let {id, pageType, node} = this.$route.query;
    this.type = pageType;
    this.nodeData = node;
    if (['detail', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    } else {
      // 新增
      this.dataForm.knowledgeTypeId = this.nodeData.id;
      this.dataForm.knowledgeTypeName = this.nodeData.knowledgeCategoryName;
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      selectById(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave: function (cancel = false) {
      this.dataForm.knowledgeCode = this.dataForm.knowledgeCode.trim()
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          try {
          } catch (e) {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$message.warning(e.message)
            return;
          }
          let data = {};
          data = this.$refs.formLayout.dataForm
          saveOrUpdate({
            ...data
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.dataForm = data
                this.initData();
                this.$refs.formLayout.$refs.form.allDisabled = false;
                this.findObject(this.$refs.formLayout.option.column, "knowledgeCode").disabled = true
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
              this.$loading().close()
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
